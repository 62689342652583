import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import '@mhe/mh-icon/src/icon-components/chevron-down';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import {
  ltiParams,
  PendoConfig,
  PendoService,
  TagManagerConfig,
  TagManagerService,
  TranslationConfigService,
  UPUtilityService,
} from 'up';
import { environment } from '../environments/environment';
import { getLtiParams } from './shared/store/selectors/ltiparams.selector';
import { LtiParamsState } from './shared/store/state/ltiparams.state';

@Component({
  selector: 'vl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [NgbAccordionConfig],
})
export class AppComponent implements OnInit, OnDestroy {
  isHeaderDisplay: boolean = true;
  isFooterDisplay: boolean = true;
  ltiParams$: Observable<ltiParams> = this.store.select(getLtiParams);
  ltiParams!: ltiParams;
  private destroy$ = new Subject<boolean>();
  constructor(
    private tagManagerService: TagManagerService,
    private pendoService: PendoService,
    private router: Router,
    private store: Store<LtiParamsState>,
    private upUtilityService: UPUtilityService,
    private translationConfigService: TranslationConfigService,
  ) {}

  ngOnInit() {
    const tagManagerConfig: TagManagerConfig = {
      env: environment.tagManagerEnv,
      auth: environment.tagManagerAuth,
    };
    this.tagManagerService.addTag(tagManagerConfig);

    this.getLtiParams();
  }

  intitiatePendo() {
    this.ltiParams;
    const pendoConfig: PendoConfig = {
      VisitorConfig: {
        id: this.ltiParams.userXid,
        mh_environment: environment.envName,
        org: this.ltiParams.organizationXid,
        mh_platform: this.ltiParams.platform,
        vl_role: this.ltiParams.role,
        mh_courseDescriptor: this.ltiParams.isbn!,
      },
      AccountConfig: {
        id: this.ltiParams.organizationXid,
        lmsName: this.ltiParams.lmsName,
      },
    };

    this.pendoService.addPendo(pendoConfig);
  }

  getLtiParams() {
    this.ltiParams$
      .pipe(takeUntil(this.destroy$))
      .subscribe((ltiParams: ltiParams) => {
        this.ltiParams = ltiParams;
        this.translationConfigService.setLocale(this.ltiParams?.lmsLocale);
        this.pageDistributor();
        if (environment.enablePendo) {
          this.intitiatePendo();
        }
      });
  }

  pageDistributor() {
    if (this.ltiParams?.isCourseCopy) {
      this.isHeaderDisplay = false;
      this.isFooterDisplay = false;
      this.router.navigateByUrl('/product/relinking/launch');
    } else if (
      this.ltiParams?.role.toLowerCase() === 'instructor' &&
      this.ltiParams?.ltiaAction === 'select' &&
      this.upUtilityService.isApplicationInFrame()
    ) {
      this.isHeaderDisplay = false;
      this.isFooterDisplay = false;
      this.router.navigateByUrl('/product/launch');
    } else if (
      this.ltiParams?.role === 'Learner' ||
      (this.ltiParams?.role === 'Instructor' &&
        this.ltiParams?.ltiaAction === 'launch')
    ) {
      if (this.upUtilityService.getPathName() === '/') {
        this.router.navigateByUrl('/assignment');
      }
    } else if (this.ltiParams?.role === 'Instructor') {
      if (location.pathname === '/') {
        this.router.navigateByUrl('/product');
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
